import React from "react";
import { Link } from "react-router-dom";
import FooterMobile from "modules/common/assets/images/footer_mobile.png";
import AppStore from "modules/common/assets/images/appStore.png";
import GooglePlay from "modules/common/assets/images/googlePlay.png";
import TwitterIcon from "modules/common/assets/images/twitter_icon.png";
import YoutubeIcon from "modules/common/assets/images/youtube_icon.png";
import "../../common/assets/css/style.scss";
const Footer = () => {
    return (
        <footer>
            <div className="footer">
                <div className="container">
                    <div className="md:flex sm:flex footer-custom">
                        <div className="md:w-3/12 space-y-2">
                            <h3 className="text-white text-xl font-bold">Chăm sóc khách hàng</h3>
                            <div className="text-white text-sm">Trung tâm trợ giúp</div>
                            <div className="text-white text-sm">Hướng dẫn thanh toán</div>
                            <div className="text-white text-sm">Thanh toán</div>
                            <div className="text-white text-sm">Chăm sóc khách hàng</div>
                        </div>
                        <div className="md:w-3/12 space-y-2">
                            <h3 className="text-white text-xl font-bold">Về BDSG</h3>
                            <div className="text-white text-sm">
                                <div className="text-sm">
                                    <a href="/about-us" target="_blank" className="text-white">
                                        Về BDSG
                                    </a>
                                </div>
                            </div>
                            <div className="text-white text-sm">
                                <div className="text-sm">
                                    <a href="/term" target="_blank" className="text-white">
                                        Điều khoản về BDSG
                                    </a>
                                </div>
                            </div>
                            <div className="text-white text-sm">
                                <div className="text-sm">
                                    <a href="/privacy" target="_blank" className="text-white">
                                        Chính sách bảo mật
                                    </a>
                                </div>
                            </div>
                            <div className="text-white text-sm">Liên hệ BDSG</div>
                        </div>
                        <div className="md:w-3/12 space-y-2">
                            <h3 className="text-white text-xl font-bold">Mạng xã hội</h3>
                            <div className="text-sm">
                                <a href="https://my.bdsggroup.com" target="_blank" className="text-white">
                                    Mạng xã hội BDSG
                                </a>
                            </div>
                            <div className="text-sm">
                                <a href="https://video.bdsggroup.com" target="_blank" className="text-white">
                                    Mạng xã hội video BDSG
                                </a>
                            </div>
                            <div className="text-sm">
                                <a href="https://news.bdsggroup.com" target="_blank" className="text-white">
                                    Mạng xã hội tin tức BDSG
                                </a>
                            </div>
                            <div className="text-sm">
                                <a href="https://ask.bdsggroup.com" target="_blank" className="text-white">
                                    Câu hỏi BDSG
                                </a>
                            </div>
                        </div>
                        <div className="md:w-3/12 space-y-2">
                            <h3 className="text-white text-xl font-bold">BUSINESS</h3>
                            <div className="text-sm">
                                <a href="https://thegioichuyendoiso.com/" target="_blank" className="text-white">
                                    Chuyển đổi kỹ thuật số
                                </a>
                            </div>
                            <div className="text-sm">
                                <a href="https://thuongmaidientu.com.vn/" target="_blank" className="text-white">
                                    Thương mại điện tử
                                </a>
                            </div>
                            <div className="text-sm">
                                <a href="https://kenhtruyenthong.com/" target="_blank" className="text-white">
                                    Tiếp thị
                                </a>
                            </div>
                            <div className="text-sm">
                                <a href="https://hocvienso.com/" target="_blank" className="text-white">
                                    Giáo dục
                                </a>
                            </div>
                            <div className="text-sm">
                                <a href="https://nhuongquyenthuongmai.com/" target="_blank" className="text-white">
                                    Nhượng quyền
                                </a>
                            </div>
                            <div className="text-sm">
                                <a href="https://thegioikhoinghiep.com/" target="_blank" className="text-white">
                                    STARTUPS
                                </a>
                            </div>
                            <div className="text-sm">
                                <a href="https://xaas.com.vn/" target="_blank" className="text-white">
                                    XAAS
                                </a>
                            </div>
                            <div className="text-sm">
                                <a href="https://xr.com.vn/" target="_blank" className="text-white">
                                    XR
                                </a>
                            </div>
                        </div>
                        <div className="md:w-3/12 space-y-2">
                            <h3 className="text-white text-xl font-bold">Tải ứng dụng ngay</h3>
                            <img src={AppStore} />
                            <img src={GooglePlay} />
                        </div>
                    </div>
                </div>
            </div>
            <div className="md:flex text-white md:h-20 sm:h-50 items-center text-center" style={{ backgroundColor: "#080446" }}>
                <div className="md:w-2/12 align-middle">© 2022 BDSG</div>
                <div className="md:w-2/12">Về chúng tôi</div>
                <div className="md:w-2/12">Metaverse</div>
                <div className="md:w-2/12">Giáo dục mở ra</div>
                <div className="md:w-2/12">Sáng kiến ​​của chúng tôi</div>
                <div className="md:w-2/12">
                    <div className="flex align-middle justify-center">
                        <img src={TwitterIcon} width="20px" />
                        <img className="ml-5" src={YoutubeIcon} width="20px" />
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
