import React, { Suspense } from "react";
const SettingMediaListWrapper = React.lazy(() => import("../components/SettingMediaListWrapper"));
const SettingMediaListPage = ({ typeName, setMenu }) => {
  return (
    <Suspense fallback={<></>}>
      <SettingMediaListWrapper typeName={typeName} setMenu={setMenu} />
    </Suspense>
  );
};

export default SettingMediaListPage;
