import { useCallback, useEffect, useState } from "react";
import { faEye, faHeart, faStar, faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Rate } from "antd";
import "modules/explore/assets/css/exploreDetail.scss";
import { useParams, Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import useLoading from "hooks/useLoading";
import { Api } from "modules/common";
import { BASE_API_URL } from "modules/common";
import toastr from "toastr";

import { ExploreService } from "../../explore/redux/services";
import { ExploreActions } from "../../explore/redux/actions";

function MyModelDetailPage() {
    const dispatch = useDispatch();
    const [exploreDetail, setExploreDetail] = useState({});
    const { turnOffLoading, turnOnLoading } = useLoading();
    const { id } = useParams();
    const { user } = useSelector((state) => state.auth);
    const { explores } = useSelector((state) => state.explore);
    const [usedModel, setUsedModel] = useState(false);
    const [isCreateModal, setIsCreateModal] = useState(true);
    const [meta3dModalId, setMeta3dModalId] = useState(null);

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });

        dispatch(ExploreActions.getMetaDetails(id));
        if (user?.meta_id) {
            ExploreService.getList3DmodelBỵMetaId(user.meta_id)
                .then((res) => {
                    if (res?.results?.length) {
                        setIsCreateModal(false);
                        setMeta3dModalId(res.results[0].id);
                        if (res.results[0].model3d_id == id) {
                            setUsedModel(true);
                        }
                    }
                })
                .catch((error) => {
                    console.log("error", error);
                });
        }
    }, []);

    const getListConstruction = async () => {
        turnOnLoading();

        try {
            const constructionRes = await Api.get(`${BASE_API_URL}/model_3d_with_meta_group/${id}`);
            setExploreDetail(constructionRes);
        } catch (err) {
            console.log(err);
        } finally {
            turnOffLoading();
        }
    };

    useEffect(() => {
        getListConstruction();
    }, []);

    const handleCreateMeta = () => {
        const params = {
            model3d_id: id,
        };
        if (isCreateModal) {
            ExploreService.createMeta3DModel(params)
                .then((res) => {
                    if (res?.data?.id) {
                        callBackUseModelSuccess();
                    } else {
                        toastr.error("Use 3D model failed");
                    }
                })
                .catch((error) => {
                    console.log("error", error);
                    toastr.error("Use 3D model error");
                });
        } else {
            params.meta3dModalId = meta3dModalId;
            ExploreService.updateMeta3DModel(params)
                .then((res) => {
                    callBackUseModelSuccess();
                })
                .catch((error) => {
                    console.log("error", error);
                    toastr.error("Use 3D model error");
                });
        }
    };

    const callBackUseModelSuccess = () => {
        toastr.success("Use this 3D model success");
        setUsedModel(true);
        if (user.domain) {
            setTimeout(() => {
                window.location.href = `https://${user.domain}`;
            }, 2000);
        }
    };

    return (
        <section className="explore-detail-page bg-theme ">
            <div className="container">
                <h2 className="text-3xl text-white font-bold">Model Detail</h2>
                <div className="flex items-center justify-between"></div>
                <div className="md:flex">
                    <div className="md:w-1/2">
                        <h1 className="home-title">{exploreDetail?.name}</h1>
                        <div className="bread-cumb text-white">Metaverse/ Park/ {exploreDetail?.name}</div>
                    </div>
                    <div className="md:w-1/2 text-right">
                        <div className="text-right mb-5">
                            <button
                                className=" text-white p-4"
                                style={{ backgroundColor: "rgb(34 211 238)" }}
                                onClick={() => handleCreateMeta()}
                            >
                                Use this model
                            </button>
                        </div>
                        <Rate className="meta-rating" disabled defaultValue={4} allowHalf />
                        <div className="meta-stats mt-2.5 flex items-center justify-end">
                            <div>
                                <FontAwesomeIcon icon={faEye} /> <span className="ml-1">{exploreDetail?.view}</span>
                            </div>
                            <div className="mx-8">
                                <FontAwesomeIcon icon={faUser} />
                                <span className="ml-1">{exploreDetail?.count_user}</span>
                            </div>
                            <div>
                                <FontAwesomeIcon icon={faHeart} />
                                <span className="ml-1">{exploreDetail?.count_like}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="w-full mt-4">
                    <img className="w-full" src={Array.isArray(exploreDetail?.images_link) ? exploreDetail?.images_link[0] : ""} />
                </div>
                <p className="mt-4 text-white">{exploreDetail?.description}</p>
            </div>
        </section>
    );
}

export default MyModelDetailPage;
