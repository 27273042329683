import { authRoute } from "../modules/auth";
import { homeRoute } from "../modules/home";
import { metaRoute } from "../modules/meta";
import { settingsRoute } from "../modules/settings";
import { exploreRoute } from "../modules/explore";
import { openInnovationRoute } from "../modules/open-innovation";
import { marketRoute } from "../modules/market";
import { productRoute } from "../modules/product";
import { portfolioRoute } from "../modules/portfolio";
import { ComingSoon } from "../modules/base/components/ComingSoon";
import { SdgsRoute } from "../modules/sdgs";

import { orderRoute } from "../modules/order";
export const routes = [
  ...authRoute,
  ...homeRoute,
  ...metaRoute,
  ...settingsRoute,
  ...exploreRoute,
  ...openInnovationRoute,
  ...marketRoute,
  ...portfolioRoute,
  ...productRoute,
  ...SdgsRoute,
  ...orderRoute,
  {
    path: "*",
    component: ComingSoon,
  },
];
