import React, { Suspense } from "react";
const OpenInnovationEditWrapper = React.lazy(() =>
  import("../components/OpenInnovationEditWrapper")
);

const OpenInnovationEditPage = () => {
  return (
    <Suspense fallback={<></>}>
      <OpenInnovationEditWrapper />
    </Suspense>
  );
};

export default OpenInnovationEditPage;
