import React, { Suspense } from "react";
const ServiceSustainabilityWrapper = React.lazy(() => import("../components/service/detail/ServiceSustainabilityWrapper"));
const ServiceSustainabilityPage = () => {
  return (
    <Suspense fallback={<></>}>
      <ServiceSustainabilityWrapper />
    </Suspense>
  );
};

export default ServiceSustainabilityPage;
