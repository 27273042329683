import PortfolioPage from "./pages/PortfolioPage";
import PortfolioEditPage from "./pages/PortfolioEditPage";

export const portfolioRoute = [
  {
    path: "/portfolio/:id",
    component: PortfolioEditPage,
    unauthenticated: true,
  },
  {
    path: "/portfolio",
    component: PortfolioPage,
    unauthenticated: true,
  },
];
