export const ACTION_TYPES = {
  LOGIN: Symbol("LOGIN"),
  LOGIN_SUCCESS: Symbol("LOGIN_SUCCESS"),
  LOGIN_ERROR: Symbol("LOGIN_ERROR"),

  SIGNUP: Symbol("SIGNUP"),
  SIGNUP_SUCCESS: Symbol("SIGNUP_SUCCESS"),
  SIGNUP_ERROR: Symbol("SIGNUP_ERROR"),

  GET_USER_INFO: Symbol("GET_USER_INFO"),
  GET_USER_INFO_SUCCESS: Symbol("GET_USER_INFO_SUCCESS"),
  GET_USER_INFO_ERROR: Symbol("GET_USER_INFO_ERROR"),
  SET_ACCOUNT: Symbol("SET_ACCOUNT"),
};
