import React, { Suspense } from "react";
const MyOrderWrapper = React.lazy(() => import("../components/MyOrderWrapper"));

const MyOrderPage = () => {
  return (
    <Suspense fallback={<></>}>
      <MyOrderWrapper />
    </Suspense>
  );
};

export default MyOrderPage;
