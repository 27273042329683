import { BackTop } from "antd";
import SettingLayoutWarper from "modules/settings/components/SettingLayoutWarper";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import "./App.scss";
import { authAction } from "./modules/auth/redux/actions";
import { AppLayout, history, PrivateRoute } from "./modules/base";
import "./modules/caches";
import { setAuth } from "./modules/caches";
import { setAccessToken } from "./modules/common";
import { marketActions } from "./modules/market/redux/actions";

function App({ routes }) {
  const dispatch = useDispatch();
  const { auth } = useSelector((state) => state.caches);

  useEffect(() => {
    if (auth) {
      setAccessToken(auth.access);
      dispatch(setAuth(auth));
    }
  }, []);

  useEffect(() => {
    if (auth?.access) {
      dispatch(authAction.getUserInfo());
      dispatch(marketActions.getAllCart());
    }
  }, [auth]);

  return (
    <>
      <Router history={history}>
        <Switch>
          {routes
            .filter(({ noAppLayout }) => noAppLayout === true)
            .map((route, k) => {
              if (route.unauthenticated) {
                return <Route exact key={k} path={route.path} component={route.component} />;
              } else {
                return <PrivateRoute exact key={k} path={route.path} component={route.component} permissions={route.permissions} />;
              }
            })}
          <AppLayout>
            <Switch>
              {routes
                .filter(({ noAppLayout }) => noAppLayout !== true)
                .map((route, k) => {
                  if (route.unauthenticated) {
                    return <Route exact key={k} path={route.path} component={route.component} />;
                  } else {
                    return <PrivateRoute exact key={k} path={route.path} component={route.component} permissions={route.permissions} />;
                  }
                })}
            </Switch>
          </AppLayout>
        </Switch>
      </Router>
      <BackTop />
    </>
  );
}

export default App;
