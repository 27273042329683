import { MarketService } from "./services";
import { ACTION_TYPES } from "./actionTypes";
import { COMPONENT_STATUS } from "../../common";

const getMarketList = (params) => {
  return (dispatch) => {
    dispatch({
      type: ACTION_TYPES.GET_MARKET_LIST,
      payload: { status: COMPONENT_STATUS.LOADING },
    });
    MarketService.getMarketList(params)
      .then((res) => {
        if (res) {
          dispatch({
            type: ACTION_TYPES.GET_MARKET_LIST,
            payload: {
              status: COMPONENT_STATUS.SUCCESS,
              data: res,
            },
          });
        } else {
          dispatch({
            type: ACTION_TYPES.GET_MARKET_LIST,
            payload: { status: COMPONENT_STATUS.ERROR },
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: ACTION_TYPES.GET_MARKET_LIST,
          payload: { status: COMPONENT_STATUS.ERROR },
        });
      });
  };
};

const addToCart = (id, callback) => {
  return (dispatch) => {
    const params = {
      model_3d: id,
    };
    MarketService.cartAddProduct(params)
      .then((res) => {
        if (res) {
          dispatch({
            type: ACTION_TYPES.CART_ADD_PRODUCT,
            payload: res,
          });
          callback(res);
        }
      })
      .catch((error) => {
        dispatch({
          type: ACTION_TYPES.GET_MARKET_DETAILS,
          payload: { statusDetails: COMPONENT_STATUS.ERROR },
        });
      });
  };
};

const removeProductCart = (id, callback) => {
  return (dispatch) => {
    const params = {
      model_3d: id,
    };
    MarketService.cartRemoveProduct(params)
      .then((res) => {
        if (res) {
          dispatch({
            type: ACTION_TYPES.CART_ADD_PRODUCT,
            payload: res,
          });
          callback(res);
        }
      })
      .catch((error) => {
        dispatch({
          type: ACTION_TYPES.GET_MARKET_DETAILS,
          payload: { statusDetails: COMPONENT_STATUS.ERROR },
        });
      });
  };
};

const getAllCart = () => {
  return (dispatch) => {
    MarketService.getAllCart()
      .then((res) => {
        if (res) {
          dispatch({
            type: ACTION_TYPES.CART_ADD_PRODUCT,
            payload: res,
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: ACTION_TYPES.GET_MARKET_DETAILS,
          payload: { statusDetails: COMPONENT_STATUS.ERROR },
        });
      });
  };
};

const getMarketDetails = (id) => {
  return (dispatch) => {
    dispatch({
      type: ACTION_TYPES.GET_MARKET_DETAILS,
      payload: { statusDetails: COMPONENT_STATUS.LOADING },
    });
    MarketService.getMarketDetails(id)
      .then((res) => {
        if (res) {
          dispatch({
            type: ACTION_TYPES.GET_MARKET_DETAILS,
            payload: {
              statusDetails: COMPONENT_STATUS.SUCCESS,
              dataDetails: res,
            },
          });
        } else {
          dispatch({
            type: ACTION_TYPES.GET_MARKET_DETAILS,
            payload: { statusDetails: COMPONENT_STATUS.ERROR },
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: ACTION_TYPES.GET_MARKET_DETAILS,
          payload: { statusDetails: COMPONENT_STATUS.ERROR },
        });
      });
  };
};

const searchKeyword = (value) => {
  return (dispatch) => {
    dispatch({
      type: ACTION_TYPES.SEARCH_KEYWORD,
      payload: {
        keyword: value,
      },
    });
  };
};

const searchMarket = (params) => {
  return (dispatch) => {
    dispatch({
      type: ACTION_TYPES.SEARCH_MARKET,
      payload: { statusSearch: COMPONENT_STATUS.LOADING },
    });
    MarketService.searchByName(params)
      .then((res) => {
        if (res) {
          dispatch({
            type: ACTION_TYPES.SEARCH_MARKET,
            payload: {
              statusSearch: COMPONENT_STATUS.SUCCESS,
              dataSearch: res,
            },
          });
        } else {
          dispatch({
            type: ACTION_TYPES.SEARCH_MARKET,
            payload: { statusSearch: COMPONENT_STATUS.ERROR },
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: ACTION_TYPES.SEARCH_MARKET,
          payload: { statusSearch: COMPONENT_STATUS.ERROR },
        });
      });
  };
};

const marketCategory = (value) => {
  return (dispatch) => {
    dispatch({
      type: ACTION_TYPES.MARKET_CATEGORY,
      payload: {
        category: value,
      },
    });
  };
};

export const marketActions = {
  getMarketList,
  getMarketDetails,
  searchMarket,
  searchKeyword,
  marketCategory,
  addToCart,
  getAllCart,
  removeProductCart,
};
