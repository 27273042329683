import ReducerRegistry from "../../base/redux/ReducerRegistry";
import { ACTION_TYPES } from "./actionTypes";

const initState = {
  marketList: {
    status: "",
    data: {},
  },
  marketDetails: {
    statusDetails: "",
    dataDetails: {},
  },
  marketSearch: {
    statusSearch: "",
    dataSearch: {},
    keyword: "",
  },
  marketCategory: {
    category: "",
  },
  cart: null,
};

ReducerRegistry.register("market", (state = initState, action) => {
  switch (action.type) {
    case ACTION_TYPES.CART_ADD_PRODUCT: {
      return {
        ...state,
        cart: action.payload,
      };
    }
    case ACTION_TYPES.GET_MARKET_LIST: {
      return {
        ...state,
        marketList: {
          ...state.marketList,
          ...action.payload,
        },
      };
    }
    case ACTION_TYPES.GET_MARKET_DETAILS: {
      return {
        ...state,
        marketDetails: {
          ...state.marketDetails,
          ...action.payload,
        },
      };
    }
    case ACTION_TYPES.SEARCH_MARKET: {
      return {
        ...state,
        marketSearch: {
          ...state.marketSearch,
          ...action.payload,
        },
      };
    }
    case ACTION_TYPES.SEARCH_KEYWORD: {
      return {
        ...state,
        marketSearch: {
          ...state.marketSearch,
          ...action.payload,
        },
      };
    }
    case ACTION_TYPES.MARKET_CATEGORY: {
      return {
        ...state,
        marketCategory: {
          ...state.marketCategory,
          ...action.payload,
        },
      };
    }
    default:
      return state;
  }
});
