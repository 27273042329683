import ProductPage from "./page/ProductPage";
import DetailProductWrapper from "./page/DetailProductPage";

export const productRoute = [
  {
    path: "/product/:id",
    component: DetailProductWrapper,
    unauthenticated: true,
  },
  {
    path: "/product",
    component: ProductPage,
    unauthenticated: true,
  },
];
