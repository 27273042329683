import React, { Suspense } from "react";
const ServiceWrapper = React.lazy(() => import("../components/service/ServiceWrapper"));
const ServicePage = () => {
  return (
    <Suspense fallback={<></>}>
      <ServiceWrapper />
    </Suspense>
  );
};

export default ServicePage;
