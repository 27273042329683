import HomePage from "./pages/HomePage";
import CookiesPolicyPage from "./pages/CookiesPolicyPage";
import PrivacyPolicyPage from "./pages/PrivacyPolicyPage";
import TermConditionsPage from "./pages/TermConditionsPage";
import CopyrightInformationPage from "./pages/CopyrightInformationPage";
import LicenseAgreementPage from "./pages/LicenseAgreementPage";
import AboutUsPage from "./pages/AboutUsPage";
import ServicePage from "./pages/ServicePage";
import ServiceDetailPage from "./pages/ServiceDigitalPage";
import ServiceStrategyPage from "./pages/ServiceStrategyPage";
import ServiceCapitalPage from "./pages/ServiceCapitalPage";
import ServiceDigitalPage from "./pages/ServiceDigitalPage";
import ServiceInnovationPage from "./pages/ServiceInnovationPage";
import ServicePerformPage from "./pages/ServicePerformPage";
import ServiceResearchPage from "./pages/ServiceResearchPage";
import ServiceSustainabilityPage from "./pages/ServiceSustainabilityPage";

export const homeRoute = [
  {
    path: "/",
    component: HomePage,
    unauthenticated: true,
  },
  {
    path: "/privacy",
    component: PrivacyPolicyPage,
    unauthenticated: true,
  },
  {
    path: "/cookie",
    component: CookiesPolicyPage,
    unauthenticated: true,
  },
  {
    path: "/term",
    component: TermConditionsPage,
    unauthenticated: true,
  },
  {
    path: "/copy-right",
    component: CopyrightInformationPage,
    unauthenticated: true,
  },
  {
    path: "/license",
    component: LicenseAgreementPage,
    unauthenticated: true,
  },
  {
    path: "/about-us",
    component: AboutUsPage,
    unauthenticated: true,
  },
  {
    path: "/service/strategy",
    component: ServiceStrategyPage,
    unauthenticated: true,
  },
  {
    path: "/service/capital",
    component: ServiceCapitalPage,
    unauthenticated: true,
  },
  {
    path: "/service/digital",
    component: ServiceDigitalPage,
    unauthenticated: true,
  },
  {
    path: "/service/innovation",
    component: ServiceInnovationPage,
    unauthenticated: true,
  },
  {
    path: "/service/perform",
    component: ServicePerformPage,
    unauthenticated: true,
  },
  {
    path: "/service/research",
    component: ServiceResearchPage,
    unauthenticated: true,
  },
  {
    path: "/service/sustainability",
    component: ServiceSustainabilityPage,
    unauthenticated: true,
  },
  {
    path: "/service",
    component: ServicePage,
    unauthenticated: true,
  },
  {
    path: "/service/:id",
    component: ServiceDetailPage,
    unauthenticated: true,
  },
];
