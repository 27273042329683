import ReducerRegistry from "../../base/redux/ReducerRegistry";
import { ACTION_TYPES } from "./actionTypes";

const initState = {
    totalCheckout: null,
    note: null,
    orderCode: null,
    paymentMethod: [],
    choosePayment: null,
    price: null,
    userOrder: null,
};

ReducerRegistry.register("order", (state = initState, action) => {
    switch (action.type) {
        case ACTION_TYPES.ADD_CHECKOUT: {
            return {
                ...state,
                cart: action.payload,
            };
        }
        case ACTION_TYPES.ADD_PAYMENT_METHOD: {
            return {
                ...state,
                choosePayment: action.payload,
            };
        }
        case ACTION_TYPES.GET_LIST_ORDER: {
            return {
                ...state,
                userOrder: action.payload,
            };
        }
        case ACTION_TYPES.ADD_NOTE_PRICE: {
            return {
                ...state,
                note: action.payload.note,
                price: action.payload.price,
                orderCode: action.payload.orderCode,
            };
        }
        case ACTION_TYPES.GET_PAYMENT_METHOD: {
            return {
                ...state,
                paymentMethod: action.payload,
            };
        }

        default:
            return state;
    }
});
