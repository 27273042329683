import ReducerRegistry from "../../base/redux/ReducerRegistry";
import { ACTION_TYPES } from "./actionTypes";

const initState = {
  explores: {
    id: null,
    domain: null,
    status: "",
    data: [],
    total: 0,
  },
  current_tab: 2,
  current_domain: null,
};

ReducerRegistry.register("explore", (state = initState, action) => {
  switch (action.type) {
    case ACTION_TYPES.GET_EXPLORES: {
      return {
        ...state,
        explores: {
          ...state.explores,
          ...action.payload,
        },
      };
    }
    case ACTION_TYPES.SET_DOMAIN: {
      return {
        ...state,
        current_domain: action.payload,
      };
    }
    case ACTION_TYPES.GET_DOMAIN: {
      return {
        ...state,
        explores: {
          ...state.explores,
          domain: action.payload?.domain,
          id: action.payload?.id,
        },
      };
    }
    case ACTION_TYPES.UPDATE_CURRENT_EXPLORE_TAB: {
      return {
        ...state,
        current_tab: action.payload,
      };
    }
    default:
      return state;
  }
});
