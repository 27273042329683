import { createContext, useState } from "react";
import "./index.scss";

export const LoadingContext = createContext({
  turnOnLoading: () => {},
  turnOffLoading: () => {},
});

const LoadingProvider = ({ children }) => {
  const [isLoading, setIsLoading] = useState(false);

  const turnOnLoading = () => {
    setIsLoading(true);
  };

  const turnOffLoading = () => {
    setIsLoading(false);
  };

  return (
    <LoadingContext.Provider value={{ turnOnLoading, turnOffLoading }}>
      {isLoading && (
        <div className="loading-container">
          <img alt="" src="/loading.gif" className="spinner" />
        </div>
      )}

      {children}
    </LoadingContext.Provider>
  );
};

export default LoadingProvider;
