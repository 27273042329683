import React, { Suspense } from "react";
const SettingJitsiWarper = React.lazy(() => import("../components/SettingJitsiWarper"));
const SettingJitsi = ({ typeName, setMenu }) => {
  return (
    <Suspense fallback={<></>}>
      <SettingJitsiWarper typeName={typeName} setMenu={setMenu} />
    </Suspense>
  );
};

export default SettingJitsi;
