import ExplorePage from "./pages/ExplorePage";
import ExploreEditPage from "./pages/ExploreEditPage";
import ModelDetailPage from "./pages/ModelDetailPage";
import ExploreDetailPage from "./pages/ExploreDetailPage";

export const exploreRoute = [
  {
    path: "/explore/:id",
    component: ExploreEditPage,
    unauthenticated: true,
  },
  {
    path: "/explore",
    component: ExplorePage,
    unauthenticated: true,
  },
  {
    path: "/explore/event/:id",
    component: ExploreEditPage,
    unauthenticated: true,
  },
  {
    path: "/explore/:parent_id/:id",
    component: ExploreDetailPage,
    unauthenticated: true,
  },
  {
    path: "/explore/event",
    component: ExplorePage,
    unauthenticated: true,
  },
  {
    path: "/model3d/model/:id",
    component: ModelDetailPage,
    unauthenticated: true,
  },
];
