import React, { Suspense } from "react";
const SdgsDetailPageWrapper = React.lazy(() => import("../components/SdgsDetailPageWrapper"));
const SdgsDetailPage = () => {
  return (
    <Suspense fallback={<></>}>
      <SdgsDetailPageWrapper />
    </Suspense>
  );
};

export default SdgsDetailPage;
