import { Api } from "../../common/utils/request";
import { BASE_API_URL } from "../../common/constants";
import queryString from "query-string";

const getMarketList = (params) => {
  const stringified = queryString.stringify(params, {
    skipEmptyString: true,
    skipNull: true,
  });

  return Api.get(`${BASE_API_URL}/model_3d/?${stringified}`);
};

const getMarketDetails = (id) => {
  return Api.get(`${BASE_API_URL}/model_3d/${id}`);
};

const searchByName = (params) => {
  const stringified = queryString.stringify(params, {
    skipEmptyString: true,
    skipNull: true,
  });
  return Api.get(`${BASE_API_URL}/model_3d/search_by_name/?${stringified}`);
};

const getList3DmodelBỵMetaId = (market_id) => {
  return Api.get(`${BASE_API_URL}/marketverse/${market_id}/market_model_3d/`);
};

const createMarket3DModel = (params) => {
  return Api.post(`${BASE_API_URL}/market_model_3d/`, params);
};

const updateMarket3DModel = (params) => {
  return Api.put(`${BASE_API_URL}/market_model_3d/${params.market3dModalId}/`, params);
};

const cartAddProduct = (params) => {
  return Api.post(`${BASE_API_URL}/admin/cart-add-product`, params);
};

const cartRemoveProduct = (params) => {
  return Api.post(`${BASE_API_URL}/admin/cart-remove-product`, params);
};

const getAllCart = () => {
  return Api.get(`${BASE_API_URL}/admin/carts`);
};

export const MarketService = {
  getMarketList,
  getMarketDetails,
  searchByName,
  createMarket3DModel,
  getList3DmodelBỵMetaId,
  updateMarket3DModel,
  cartAddProduct,
  cartRemoveProduct,
  getAllCart,
};
