import React, { Suspense } from "react";
const OpenInnovationWrapper = React.lazy(() =>
  import("../components/OpenInnovationWrapper")
);

const OpenInnovationPage = () => {
  return (
    <Suspense fallback={<></>}>
      <OpenInnovationWrapper />
    </Suspense>
  );
};

export default OpenInnovationPage;
