import React, { Suspense, useEffect } from "react";
const ForgotPasswordWrapper = React.lazy(() => import("../components/ForgotPasswordWrapper"));

const ForgotPasswordPage = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  return (
    <Suspense fallback={<></>}>
      <ForgotPasswordWrapper />
    </Suspense>
  );
};

export default ForgotPasswordPage;
