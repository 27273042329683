import React, { Suspense } from "react";
const MarketEditWrapper = React.lazy(() =>
  import("../components/MarketEditWrapper")
);

const MarketEditPage = () => {
  return (
    <Suspense fallback={<></>}>
      <MarketEditWrapper />
    </Suspense>
  );
};

export default MarketEditPage;
