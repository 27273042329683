import CheckoutPage from "./pages/CheckoutPage";
import MyOrderPage from "./pages/MyOrderPage";
import OrderPage from "./pages/OrderPage";
import MyOrderEditPage from "./pages/MyOrderEditPage";

export const orderRoute = [
  {
    path: "/cart",
    component: OrderPage,
    unauthenticated: true,
  },
  {
    path: "/checkout",
    component: CheckoutPage,
    unauthenticated: true,
  },
  {
    path: "/my-order",
    component: MyOrderPage,
    unauthenticated: true,
  },
  {
    path: "/my-order/:id",
    component: MyOrderEditPage,
    unauthenticated: true,
  },
];
