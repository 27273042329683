import React, { Suspense } from "react";
const DetailProductWrapper = React.lazy(() =>
  import("../components/DetailProductWrapper")
);

const ProductPage = () => {
  return (
    <Suspense fallback={<></>}>
      <DetailProductWrapper />
    </Suspense>
  );
};

export default ProductPage;
