import React, { Suspense } from "react";
const MyOrderEditWrapper = React.lazy(() => import("../components/MyOrderEditWrapper"));

const MyOrderEditPage = () => {
  return (
    <Suspense fallback={<></>}>
      <MyOrderEditWrapper />
    </Suspense>
  );
};

export default MyOrderEditPage;
