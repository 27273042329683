import OpenInnovationPage from "./pages/OpenInnovationPage";
import OpenInnovationEditPage from "./pages/OpenInnovationEditPage";

export const openInnovationRoute = [
  {
    path: "/challenge/:id",
    component: OpenInnovationEditPage,
    unauthenticated: true,
  },
  {
    path: "/challenge",
    component: OpenInnovationPage,
    unauthenticated: true,
  },
];
