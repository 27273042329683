import { Api } from "../../common/utils/request";
import { BASE_API_URL } from "../../common/constants";

const login = (data) => {
  return Api.post(`${BASE_API_URL}/token/`, data);
};

const signup = (data) => {
  return Api.post(`${BASE_API_URL}/admin/user/`, data);
};

const getUserInfo = () => {
  return Api.get(`${BASE_API_URL}/user/get_info/`);
};

const apiGetUserTypes = () => {
  return Api.get(`${BASE_API_URL}/admin/user-type`);
};

const verifiedEmail = (token, data) => {
  return Api.put(`${BASE_API_URL}/token/verify-mail/${token}/`, data);
};

const resentCode = (data) => {
  return Api.post(`${BASE_API_URL}/token/re-sent/`, data);
};

const changePassword = (data) => {
  return Api.post(`${BASE_API_URL}/token/forgot-password/`, data);
};

const changeNewPassword = (token, data) => {
  return Api.put(`${BASE_API_URL}/token/verify-forgot-password/${token}/`, data);
};

export const AuthService = {
  login,
  signup,
  getUserInfo,
  apiGetUserTypes,
  verifiedEmail,
  resentCode,
  changePassword,
  changeNewPassword,
};
