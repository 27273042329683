import { useEffect, useState } from "react";
import { BASE_API_URL } from "../../common/constants";
import { Api } from "../../common/utils/request";

import { Dropdown, Button } from "antd";

import "../assets/css/navigation.scss";

const Navigation = ({ isMobile = false }) => {
    const [navigation, setNavigation] = useState([]);

    const loadNavigationFromSer = async () => {
        const url = `${BASE_API_URL}/admin/navigation`;
        const response = await Api.get(url, { meta_group_id: 2 });
        if (Array.isArray(response?.results) && response.results.length) {
            const menuItem = [...response?.results.sort((a, b) => a.priority - b.priority)].map((item) => ({
                key: item.id,
                label: (
                    <a className="navigation-item" href={item.link} target="_blank">
                        <img src={item?.icon} alt="" />
                        <div className="mt-2">{item?.name}</div>
                    </a>
                ),
            }));
            setNavigation(menuItem);
        }
    };

    useEffect(() => {
        loadNavigationFromSer();
    }, []);

    const menuProps = {
        items: navigation,
        mode: "horizontal",
    };

    return (
        <Dropdown
            overlayClassName={`navigation-btn-over ${isMobile ? "mobile" : ""}`}
            className={`navigation-btn ${isMobile ? "mobile" : ""}`}
            menu={menuProps}
            trigger={["click"]}
        >
            <Button>
                <svg width="48" height="24" viewBox="0 0 48 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M12 13.5C12.8284 13.5 13.5 12.8284 13.5 12C13.5 11.1716 12.8284 10.5 12 10.5C11.1716 10.5 10.5 11.1716 10.5 12C10.5 12.8284 11.1716 13.5 12 13.5Z"
                        stroke="#00FFFF"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    />
                    <path
                        d="M12 6.5C12.8284 6.5 13.5 5.82843 13.5 5C13.5 4.17157 12.8284 3.5 12 3.5C11.1716 3.5 10.5 4.17157 10.5 5C10.5 5.82843 11.1716 6.5 12 6.5Z"
                        stroke="#00FFFF"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    />
                    <path
                        d="M12 20.5C12.8284 20.5 13.5 19.8284 13.5 19C13.5 18.1716 12.8284 17.5 12 17.5C11.1716 17.5 10.5 18.1716 10.5 19C10.5 19.8284 11.1716 20.5 12 20.5Z"
                        stroke="#00FFFF"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    />
                    <path
                        d="M24 13.5C24.8284 13.5 25.5 12.8284 25.5 12C25.5 11.1716 24.8284 10.5 24 10.5C23.1716 10.5 22.5 11.1716 22.5 12C22.5 12.8284 23.1716 13.5 24 13.5Z"
                        stroke="#00FFFF"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    />
                    <path
                        d="M24 6.5C24.8284 6.5 25.5 5.82843 25.5 5C25.5 4.17157 24.8284 3.5 24 3.5C23.1716 3.5 22.5 4.17157 22.5 5C22.5 5.82843 23.1716 6.5 24 6.5Z"
                        stroke="#00FFFF"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    />
                    <path
                        d="M24 20.5C24.8284 20.5 25.5 19.8284 25.5 19C25.5 18.1716 24.8284 17.5 24 17.5C23.1716 17.5 22.5 18.1716 22.5 19C22.5 19.8284 23.1716 20.5 24 20.5Z"
                        stroke="#00FFFF"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    />
                    <path
                        d="M36 13.5C36.8284 13.5 37.5 12.8284 37.5 12C37.5 11.1716 36.8284 10.5 36 10.5C35.1716 10.5 34.5 11.1716 34.5 12C34.5 12.8284 35.1716 13.5 36 13.5Z"
                        stroke="#00FFFF"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    />
                    <path
                        d="M36 6.5C36.8284 6.5 37.5 5.82843 37.5 5C37.5 4.17157 36.8284 3.5 36 3.5C35.1716 3.5 34.5 4.17157 34.5 5C34.5 5.82843 35.1716 6.5 36 6.5Z"
                        stroke="#00FFFF"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    />
                    <path
                        d="M36 20.5C36.8284 20.5 37.5 19.8284 37.5 19C37.5 18.1716 36.8284 17.5 36 17.5C35.1716 17.5 34.5 18.1716 34.5 19C34.5 19.8284 35.1716 20.5 36 20.5Z"
                        stroke="#00FFFF"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    />
                </svg>
            </Button>
        </Dropdown>
    );
};

export default Navigation;
