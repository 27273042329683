import MarketPage from "./pages/MarketPage";
import MarketEditPage from "./pages/MarketEditPage";

export const marketRoute = [
  {
    path: "/market/:id",
    component: MarketEditPage,
    unauthenticated: true
  },
  {
    path: "/market",
    component: MarketPage,
    unauthenticated: true
  },
];
