import React, { Suspense } from "react";
const ServiceInnovationWrapper = React.lazy(() => import("../components/service/detail/ServiceInnovationWrapper"));
const ServiceInnovationPage = () => {
  return (
    <Suspense fallback={<></>}>
      <ServiceInnovationWrapper />
    </Suspense>
  );
};

export default ServiceInnovationPage;
