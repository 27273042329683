import React from "react";
import { Fragment, useState } from "react";
import { Dialog, Menu, Transition } from "@headlessui/react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import classNames from "classnames";

const AvatarDropdown = ({ dark = false }) => {
    const { user } = useSelector((state) => state.auth);
    const logout = () => {
        localStorage.clear();
        window.location.href = "/login";
    };

    return (
        <Menu as="div" className="px-3 relative block text-center">
            {({ open }) => (
                <>
                    <div>
                        <Menu.Button className="flex items-center cursor-pointer">
                            <img
                                src={
                                    user?.avatar
                                        ? `https://asset.airclass.io/public/${user.avatar}`
                                        : `https://ui-avatars.com/api/?name=${user?.email}&background=${dark ? "000" : "fff"}&color=${
                                              dark ? "fff" : "3d3d3d"
                                          }`
                                }
                                className="rounded-full w-12 "
                                alt="Avatar"
                            />
                            <div
                                style={{ color: dark ? "black" : "white", height: "48px", width: "150px" }}
                                className="mb-0 ml-2 items-center user-email flex text-overflow"
                            >
                                {user.email}
                            </div>
                        </Menu.Button>
                    </div>
                    <Transition
                        show={open}
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                    >
                        <Menu.Items static className="avatar-dropdown-position">
                            <Menu.Item style={{ minHeight: "40px" }}>
                                <Link to="/setting" className="flex items-center cursor-pointer px-4 py-2 text-gray-700">
                                    Setting
                                </Link>
                            </Menu.Item>
                            <Menu.Item style={{ minHeight: "40px" }}>
                                <Link to="/my-order/" className="flex items-center cursor-pointer px-4 py-2 text-gray-700">
                                    My order
                                </Link>
                            </Menu.Item>
                            <Menu.Item style={{ minHeight: "40px" }}>
                                <Link to="/my-model-3d/" className="flex items-center cursor-pointer px-4 py-2 text-gray-700">
                                    My model 3D
                                </Link>
                            </Menu.Item>
                            <Menu.Item style={{ minHeight: "40px" }}>
                                {({ active }) => (
                                    <div onClick={logout} className={"block px-4 py-2 text-sm text-gray-700 cursor-pointer text-left"}>
                                        Logout
                                    </div>
                                )}
                            </Menu.Item>
                        </Menu.Items>
                    </Transition>
                </>
            )}
        </Menu>
    );
};
export default React.memo(AvatarDropdown);
