import React, { Suspense } from "react";
const SettingLayoutWarper = React.lazy(() => import("../components/SettingLayoutWarper"));
const SettingLayoutPage = () => {
  return (
    <Suspense fallback={<></>}>
      <SettingLayoutWarper />
    </Suspense>
  );
};

export default SettingLayoutPage;
