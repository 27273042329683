import React, { Suspense } from "react";
const ExploreDetailPageWrapper = React.lazy(() => import("../components/ExploreDetailPageWrapper"));

const ExploreDetailPage = () => {
  return (
    <Suspense fallback={<></>}>
      <ExploreDetailPageWrapper />
    </Suspense>
  );
};

export default ExploreDetailPage;
