import React, { Suspense, useEffect } from "react";
const VerifyEmailWrapper = React.lazy(() => import("../components/VerifyEmailWrapper"));

const VerifyEmailPage = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  return (
    <Suspense fallback={<></>}>
      <VerifyEmailWrapper />
    </Suspense>
  );
};

export default VerifyEmailPage;
