import React, { Suspense } from "react";
const OrderPageWrapper = React.lazy(() => import("../components/OrderPageWrapper"));

const OrderPage = () => {
  return (
    <Suspense fallback={<></>}>
      <OrderPageWrapper />
    </Suspense>
  );
};

export default OrderPage;
