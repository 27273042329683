import LoginPage from "./pages/LoginPage";
import SignupPage from "./pages/SignupPage";
import VerifyEmailPage from "./pages/VerifyEmailPage";
import ForgotPasswordPage from "./pages/ForgotPasswordPage";

export const authRoute = [
  {
    path: "/login",
    component: LoginPage,
    unauthenticated: true,
  },
  {
    path: "/signup",
    component: SignupPage,
    unauthenticated: true,
  },
  {
    path: "/verify-email",
    component: VerifyEmailPage,
    unauthenticated: true,
  },
  {
    path: "/forgot-password/:token",
    component: ForgotPasswordPage,
    unauthenticated: true,
  },
  {
    path: "/forgot-password",
    component: ForgotPasswordPage,
    unauthenticated: true,
  },
];
