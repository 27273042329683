import { useEffect, useState } from "react";
import {
    faEarthAmerica,
    faTrophy,
    faMedal,
    faHome,
    faHandsHoldingChild,
    faToolbox,
    faAtom,
    faBagShopping,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, useLocation } from "react-router-dom";

import "../assets/css/sidebar.scss";

export const menuItems = [
    {
        title: "Trang chủ",
        icon: faHome,
        href: "/",
    },
    { title: "Khám phá", icon: faEarthAmerica, href: "/explore", keyActive: "explore" },
    {
        title: "Thử thách",
        icon: faTrophy,
        href: "/challenge",
        keyActive: "challenge",
    },
    { title: "Danh mục đầu tư", icon: faMedal, href: "/portfolio", keyActive: "portfolio" },
    { title: "Sản phẩm", icon: faToolbox, href: "/product", keyActive: "product" },
    { title: "Dịch vụ", icon: faHandsHoldingChild, href: "/service", keyActive: "service" },
    { title: "Cửa hàng", icon: faBagShopping, href: "/market", keyActive: "market" },
    { title: "SDGS", icon: faAtom, href: "/sdgs", keyActive: "sdgs" },
    // { title: "Profile", icon: faUser, href: "/setting/profile" },
];

const SidebarMenu = () => {
    const { pathname } = useLocation();

    const [menu, setMenu] = useState([]);

    const checkActive = (item) => {
        const { href = "", keyActive = "" } = item;
        const firstCondition = pathname === href;
        // const link = item.link;
        // const keyActive = item.link.replace("/", "");
        // const firstCondition = pathname === link;
        const secondCondition = keyActive && pathname.includes(keyActive);
        if (firstCondition || secondCondition) return "active";
        return "";
    };

    // const loadNavigationFromSer = async () => {
    //   const url = `${BASE_API_URL}/admin/navigation`;
    //   const response = await Api.get(url, { meta_group_id: 2 });
    //   if (Array.isArray(response?.results) && response.results.length) {
    //     console.log("response?.results :>> ", response?.results);
    //     setMenu(response?.results.sort((a, b) => a.priority - b.priority));
    //   }
    // };

    // useEffect(() => {
    //   loadNavigationFromSer();
    // }, []);

    return (
        <div className="sidebar-menu">
            <ul>
                {menuItems.map((item, k) => (
                    <li className={`sidebar-menu-item ${checkActive(item)}`} key={k}>
                        <Link to={item.href}>
                            <FontAwesomeIcon className="sidebar-menu-item-icon" icon={item.icon} />
                            <div className="sidebar-menu-item-text">{item.title}</div>
                        </Link>
                    </li>
                ))}
                {/* {menu?.length
          ? menu.map((menuItem) => (
              <li className={`sidebar-menu-item ${checkActive(menuItem)}`} key={menuItem.id}>
                <Link to={menuItem.link}>
                  <img src={menuItem.icon} alt="" />
                  <div className="sidebar-menu-item-text mt-2">{menuItem.name}</div>
                </Link>
              </li>
            ))
          : ""} */}
            </ul>
        </div>
    );
};

export default SidebarMenu;
