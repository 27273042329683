import SettingMediaListPage from "./pages/SettingMediaListPage";
import SettingMediaCreatePage from "./pages/SettingMediaCreatePage";
import SettingMetaDomainPage from "./pages/SettingMetaDomainPage";
import SettingMetaPage from "./pages/SettingMetaPage";
import SettingProfilePage from "./pages/SettingProfilePage";
import SettingShopPage from "./pages/SettingShopPage";
import MyModel3dPage from "./pages/MyModel3dPage";
import SettingLayoutPage from "./pages/SettingLayoutPage";
import MyModelDetailPage from "./components/MyModelDetailPage";

export const settingsRoute = [
    {
        path: "/setting",
        component: SettingLayoutPage,
    },
    {
        path: "/setting/metaDomain",
        component: SettingMetaDomainPage,
    },
    {
        path: "/setting/metaSetting",
        component: SettingMetaPage,
    },
    {
        path: "/setting/shop",
        component: SettingShopPage,
    },
    {
        path: "/setting/profile",
        component: SettingProfilePage,
    },
    {
        path: "/setting/:typeName/create",
        component: SettingMediaCreatePage,
    },
    {
        path: "/setting/AUDIO",
        component: SettingLayoutPage,
    },
    {
        path: "/setting/EBOOK",
        component: SettingLayoutPage,
    },
    {
        path: "/setting/VIDEO",
        component: SettingLayoutPage,
    },
    {
        path: "/my-model-3d",
        component: MyModel3dPage,
    },
    {
        path: "/my-model-3d/:id",
        component: MyModelDetailPage,
    },
];
