import React, { Suspense } from "react";
const MyModel3dPageWarper = React.lazy(() => import("../components/MyModel3dPageWarper"));
const MyModel3dPage = () => {
  return (
    <Suspense fallback={<></>}>
      <MyModel3dPageWarper />
    </Suspense>
  );
};

export default MyModel3dPage;
