import { useEffect, useState } from "react";
import {
  faBasketShopping,
  faBook,
  faCircleQuestion,
  faEarthAmericas,
  faEnvelope,
  faGear,
  faGraduationCap,
  faPaperPlane,
  faVideo,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, useLocation } from "react-router-dom";

import "../assets/css/sidebar.scss";
import { MENU_TYPE } from "../utils/settingMediaUtils";
const menuItems = [
  {
    title: "My Metaverse",
    icon: faEarthAmericas,
    href: MENU_TYPE.MY_METAVERSE,
  },
  // { title: "Meta Setting", icon: faEnvelope, href: MENU_TYPE.META_SETTING },
  { title: "Audio", icon: faPaperPlane, href: MENU_TYPE.AUDIO },
  { title: "Video", icon: faVideo, href: MENU_TYPE.VIDEO },
  { title: "Ebook", icon: faBook, href: MENU_TYPE.EBOOK },
  { title: "Meeting", icon: faGraduationCap, href: MENU_TYPE.MEETING },
  // { title: "Shop", icon: faBasketShopping, href: "/setting/shop" },
  // { title: "Profile", icon: faGear, href: "/setting/profile" },
  // { title: "Support", icon: faCircleQuestion, href: "/setting/support" },
];

const SidebarSettingMenu = ({ setMenu }) => {
  const { pathname } = useLocation();
  const checkActive = (item) => {
    const { href = "", keyActive = "" } = item;
    const firstCondition = pathname === href;
    // const link = item.link;
    // const keyActive = item.link.replace("/", "");
    // const firstCondition = pathname === link;
    const secondCondition = keyActive && pathname.includes(keyActive);
    if (firstCondition || secondCondition) return "active";
    return "";
  };

  return (
    <div class="sidebar-setting-menu shadow-md px-0 w-3/4">
      <ul class="relative">
        {menuItems.map((item, index) => (
          <li class="relative overflow-hidden">
            <div
              class={`${
                pathname.includes(item.href) ? "menu-item-active" : ""
              } menu-item cursor-pointer flex items-center text-sm py-4 px-6 h-12 overflow-hidden text-ellipsis whitespace-nowrap rounded hover:bg-gray-100 transition duration-300 ease-in-out`}
              data-mdb-ripple="true"
              data-mdb-ripple-color="#f2f2f2"
              onClick={() => setMenu(item.href)}
            >
              <FontAwesomeIcon style={{ color: "white" }} icon={item.icon} />
              <span className="ml-3 text-white">{item.title}</span>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default SidebarSettingMenu;
