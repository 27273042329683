import SdgsPage from "./pages/SdgsPage";
import SdgsDetailPage from "./pages/SdgsDetailPage";

export const SdgsRoute = [
  {
    path: "/sdgs",
    component: SdgsPage,
    unauthenticated: true,
  },
  {
    path: "/sdgs/:id",
    component: SdgsDetailPage,
    unauthenticated: true,
  },
];
