import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import MobileMenu from "../../home/components/MobileMenu";
import HeaderLogo from "modules/common/assets/images/logo.png";
import AvatarDropdown from "./AvatarDropdown";
import Navigation from "./Navigation";

const Header = ({ solidBackground }) => {
    const { user } = useSelector((state) => state.auth);
    const { cart } = useSelector((state) => state.market);

    const [showModal, setShowModal] = useState(false);
    const [headerBackground, setHeaderBackground] = useState(solidBackground ? "bg-header-scroll" : "bg-header");

    const listenScrollEvent = (e) => {
        if (solidBackground || window.scrollY > 400) {
            setHeaderBackground("bg-header-scroll");
        } else {
            setHeaderBackground("bg-header");
        }
    };

    useEffect(() => {
        window.addEventListener("scroll", listenScrollEvent);

        return () => {
            window.removeEventListener("scroll", listenScrollEvent);
        };
    }, []);

    const handleClickLogo = () => {
        if (window.location.pathname === "/") {
            window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        }
    };

    return (
        <header className={headerBackground}>
            <div className="container" style={{ height: "100%" }}>
                <div className="flex items-center justify-between " style={{ height: "100%" }}>
                    <div className="header-left">
                        <Link to="/" onClick={handleClickLogo}>
                            <img className="w-40 lg:w-100" src={HeaderLogo} alt="bdsg" />
                        </Link>
                    </div>
                    <div className="header-right items-center hidden sm:flex">
                        {!user ? (
                            <>
                                <Link className="py-1 px-4 w-max text-primary font-medium uppercase text-white" to="/login">
                                    Đăng nhập
                                </Link>
                                <Navigation />
                            </>
                        ) : (
                            <div className="flex" style={{ alignItems: "center" }}>
                                <AvatarDropdown />
                                <Navigation />
                                <div className="px-3">
                                    <li class="font-sans block lg:inline-block lg:mt-0  align-middle text-white hover:text-gray-700">
                                        <a href="/cart" role="button" class="relative flex" style={{ height: "24px" }}>
                                            <svg class="flex-1 w-8 h-8 fill-current" viewbox="0 0 24 24">
                                                <path
                                                    className="text-white"
                                                    d="M17,18C15.89,18 15,18.89 15,20A2,2 0 0,0 17,22A2,2 0 0,0 19,20C19,18.89 18.1,18 17,18M1,2V4H3L6.6,11.59L5.24,14.04C5.09,14.32 5,14.65 5,15A2,2 0 0,0 7,17H19V15H7.42A0.25,0.25 0 0,1 7.17,14.75C7.17,14.7 7.18,14.66 7.2,14.63L8.1,13H15.55C16.3,13 16.96,12.58 17.3,11.97L20.88,5.5C20.95,5.34 21,5.17 21,5A1,1 0 0,0 20,4H5.21L4.27,2M7,18C5.89,18 5,18.89 5,20A2,2 0 0,0 7,22A2,2 0 0,0 9,20C9,18.89 8.1,18 7,18Z"
                                                />
                                            </svg>
                                            <Link to="/cart">
                                                <span class="absolute right-0 top-0 rounded-full bg-red-600 w-4 h-4 top right p-0 m-0 text-white font-mono text-sm  leading-tight text-center">
                                                    {cart?.results?.length > 0 ? cart?.results?.length : 0}
                                                </span>
                                            </Link>
                                        </a>
                                    </li>
                                </div>
                            </div>
                        )}
                    </div>
                    <MobileMenu cart={cart} />
                </div>
                <div className="hidden mt-3 flex col-end-4 justify-end ml-28 w-72 h-32">
                    {showModal && <div className="hidden rounded-md  bg-white text-black">Thông báo</div>}
                </div>
            </div>
        </header>
    );
};

export default Header;
