import React, { useState, Fragment } from "react";
import SidebarSettingMenu from "./SidebarSettingMenu";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { settingsRoute } from "../routes";
import { history, PrivateRoute } from "../../../modules/base";
import SettingMetaPage from "../pages/SettingMetaPage";
import SettingMetaDomainPage from "../pages/SettingMetaDomainPage";
import SettingMediaCreatePage from "../pages/SettingMediaCreatePage";
import SettingMediaListPage from "../pages/SettingMediaListPage";
import { MENU_TYPE } from "../utils/settingMediaUtils";
import SettingJitsi from "../pages/SettingJitsi";
import SettingJitsiCreatePage from "../pages/SettingJitsiCreatePage";

import "../assets/css/mobile_custom.scss";

const SettingLayoutWarper = () => {
    const [menu, setMenu] = useState(MENU_TYPE.MY_METAVERSE);

    return (
        <section className="section-page setting-page">
            <div className="px-5 container">
                <div className="flex wrapper">
                    <div className="w-3/12 mt-10 sidebar-wrapper">
                        <SidebarSettingMenu setMenu={setMenu} />
                    </div>
                    <div className="w-9/12 mb-20 overflow-y-hidden content-wrapper">
                        {menu == MENU_TYPE.MY_METAVERSE && <SettingMetaDomainPage />}
                        {menu == MENU_TYPE.META_SETTING && <SettingMetaPage />}
                        {menu == MENU_TYPE.AUDIO && <SettingMediaListPage typeName={menu} setMenu={setMenu} />}
                        {menu == MENU_TYPE.VIDEO && <SettingMediaListPage typeName={menu} setMenu={setMenu} />}
                        {menu == MENU_TYPE.EBOOK && <SettingMediaListPage typeName={menu} setMenu={setMenu} />}
                        {menu == MENU_TYPE.MEETING && <SettingJitsi typeName={menu} setMenu={setMenu} />}
                        {menu == MENU_TYPE.CREATE_AUDIO && <SettingMediaCreatePage typeName={MENU_TYPE.AUDIO} />}
                        {menu == MENU_TYPE.CREATE_VIDEO && <SettingMediaCreatePage typeName={MENU_TYPE.VIDEO} />}
                        {menu == MENU_TYPE.CREATE_EBOOK && <SettingMediaCreatePage typeName={MENU_TYPE.EBOOK} />}
                        {menu == MENU_TYPE.CREATE_MEETING && <SettingJitsiCreatePage typeName={MENU_TYPE.MEETING} />}
                    </div>
                </div>
            </div>
        </section>
    );
};

export default SettingLayoutWarper;
