import React, { Suspense } from "react";
const AboutUsWrapper = React.lazy(() => import("../components/AboutUsWrapper"));
const AboutUsPage = () => {
  return (
    <Suspense fallback={<></>}>
      <AboutUsWrapper />
    </Suspense>
  );
};

export default AboutUsPage;
