import React, { Suspense } from "react";
const CheckoutPageWrapper = React.lazy(() => import("../components/CheckoutPageWrapper"));

const CheckoutPage = () => {
  return (
    <Suspense fallback={<></>}>
      <CheckoutPageWrapper />
    </Suspense>
  );
};

export default CheckoutPage;
