import React, { Suspense } from "react";
const SettingMediaCreateWrapper = React.lazy(() => import("../components/SettingMediaCreateWrapper"));
const SettingMediaCreatePage = ({ typeName }) => {
  return (
    <Suspense fallback={<></>}>
      <SettingMediaCreateWrapper typeName={typeName} />
    </Suspense>
  );
};

export default SettingMediaCreatePage;
