import React, { Suspense } from "react";
const ServiceCapitalWrapper = React.lazy(() => import("../components/service/detail/ServiceCapitalWrapper"));
const ServiceCapitalPage = () => {
  return (
    <Suspense fallback={<></>}>
      <ServiceCapitalWrapper />
    </Suspense>
  );
};

export default ServiceCapitalPage;
