import { useState, useEffect } from "react";
import { Button, Drawer, Menu } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { menuItems } from "modules/base/components/SidebarMenu";
import { Api } from "../../common/utils/request";
import { BASE_API_URL } from "../../common/constants";
import { useSelector } from "react-redux";
import AvatarDropdown from "modules/common/components/AvatarDropdown";
import Navigation from "modules/common/components/Navigation";

import "../assets/css/menu-mobile.scss";

const mobileMenus = menuItems?.concat([
    {
        title: "Đăng nhập",
        href: "/login",
        key: "login",
    },
    {
        title: "Create an account",
        href: "/signup",
        key: "signup",
    },
]);

const MobileMenu = ({ cart }) => {
    const [menu, setMenu] = useState([]);
    const [visible, setVisible] = useState(false);
    const { user } = useSelector((state) => state.auth);
    const showDrawer = () => {
        setVisible(true);
    };

    const onClose = () => {
        setVisible(false);
    };

    const loadNavigationFromSer = async () => {
        const url = `${BASE_API_URL}/admin/navigation`;
        const response = await Api.get(url, { meta_group_id: 2 });
        if (Array.isArray(response?.results) && response.results.length) {
            console.log("response?.results :>> ", response?.results);
            setMenu(response?.results.sort((a, b) => a.priority - b.priority));
        }
    };

    const renderMenu = (menus) => {
        if (user) {
            return [...menus].filter((menu) => !["login", "signup"].includes(menu.key));
        }
        return menus;
    };

    useEffect(() => {
        loadNavigationFromSer();
    }, []);

    return (
        <div className="block sm:hidden">
            <div className="flex items-center">
                <Navigation isMobile={true} />
                <div className="px-3">
                    <li class="font-sans block lg:inline-block lg:mt-0  align-middle text-white hover:text-gray-700">
                        <a href="/cart" role="button" class="relative flex" style={{ height: "24px" }}>
                            <svg class="flex-1 w-8 h-8 fill-current" viewbox="0 0 24 24">
                                <path
                                    className="text-white"
                                    d="M17,18C15.89,18 15,18.89 15,20A2,2 0 0,0 17,22A2,2 0 0,0 19,20C19,18.89 18.1,18 17,18M1,2V4H3L6.6,11.59L5.24,14.04C5.09,14.32 5,14.65 5,15A2,2 0 0,0 7,17H19V15H7.42A0.25,0.25 0 0,1 7.17,14.75C7.17,14.7 7.18,14.66 7.2,14.63L8.1,13H15.55C16.3,13 16.96,12.58 17.3,11.97L20.88,5.5C20.95,5.34 21,5.17 21,5A1,1 0 0,0 20,4H5.21L4.27,2M7,18C5.89,18 5,18.89 5,20A2,2 0 0,0 7,22A2,2 0 0,0 9,20C9,18.89 8.1,18 7,18Z"
                                />
                            </svg>
                            <Link to="/cart">
                                <span class="absolute right-0 top-0 rounded-full bg-red-600 w-4 h-4 top right p-0 m-0 text-white font-mono text-sm  leading-tight text-center">
                                    {cart?.results?.length > 0 ? cart?.results?.length : 0}
                                </span>
                            </Link>
                        </a>
                    </li>
                </div>
                <Button type="text" onClick={showDrawer}>
                    <FontAwesomeIcon icon={faBars} style={{ color: "#fff", fontSize: 28 }} />
                </Button>
            </div>
            <Drawer width={250} title="Menu" placement="right" onClose={onClose} visible={visible} className="mobile-menu-drawer">
                <Menu>
                    {user ? (
                        <div className="py-3">
                            <AvatarDropdown dark={true} />
                        </div>
                    ) : (
                        ""
                    )}
                    {renderMenu(mobileMenus)?.map(({ href, title }, index) => (
                        <Menu.Item key={index}>
                            <Link to={href}>{title}</Link>
                        </Menu.Item>
                    ))}
                    {user ? (
                        <Menu.Item
                            key={"123"}
                            onClick={() => {
                                localStorage.clear();
                                window.location.href = "/login";
                            }}
                        >
                            <Link>Logout</Link>
                        </Menu.Item>
                    ) : (
                        ""
                    )}
                </Menu>
            </Drawer>
        </div>
    );
};

export default MobileMenu;
