import React, { Suspense } from "react";
const SettingJitsiCreateWrapper = React.lazy(() => import("../components/SettingJitsiCreateWrapper"));
const SettingJitsiCreatePage = ({ typeName }) => {
  return (
    <Suspense fallback={<></>}>
      <SettingJitsiCreateWrapper typeName={typeName} />
    </Suspense>
  );
};

export default SettingJitsiCreatePage;
