import React, { Suspense } from "react";
const MarketWrapper = React.lazy(() => import("../components/MarketWrapper"));

const MarketPage = () => {
  return (
    <Suspense fallback={<></>}>
      <MarketWrapper />
    </Suspense>
  );
};

export default MarketPage;
