import React, { Suspense } from "react";
const ServicePerformWrapper = React.lazy(() => import("../components/service/detail/ServicePerformWrapper"));
const ServicePerformPage = () => {
  return (
    <Suspense fallback={<></>}>
      <ServicePerformWrapper />
    </Suspense>
  );
};

export default ServicePerformPage;
