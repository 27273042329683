export const ACTION_TYPES = {
  GET_EXPLORES: Symbol("GET_EXPLORES"),
  GET_DOMAIN: Symbol("GET_DOMAIN"),
  UPDATE_CURRENT_EXPLORE_TAB: Symbol("UPDATE_CURRENT_EXPLORE_TAB"),
  GET_META_LIST: Symbol("GET_META_LIST"),
  GET_META_DETAILS: Symbol("GET_META_DETAILS"),
  SEARCH_META: Symbol("SEARCH_META"),
  SEARCH_KEYWORD: Symbol("SEARCH_KEYWORD"),
  META_CATEGORY: Symbol("META_CATEGORY"),
  SET_DOMAIN: Symbol("SET_DOMAIN"),
};
